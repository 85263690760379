.audi-headline-order-1 {
	font-family: AudiTypeExtended, sans-serif;
	font-size: 2.7rem;
	line-height: 4.2rem;
}

@media all and (min-width: 768px) {
	.audi-headline-order-1 {
		font-size: 3.6rem;
		line-height: 4.8rem;
	}
}

@media all and (min-width: 1024px) {
	.audi-headline-order-1 {
		font-size: 4.2rem;
		line-height: 6rem;
	}
}

@media all and (min-width: 1440px) {
	.audi-headline-order-1 {
		font-size: 5.4rem;
		line-height: 7.8rem;
	}
}

@media all and (min-width: 1920px) {
	.audi-headline-order-1 {
		font-size: 6.6rem;
		line-height: 9.6rem;
	}
}

.audi-ordered-list-s,
.audi-ordered-list-m {
	counter-reset: audi-custom-counter;
	display: table;
	font-family: AudiTypeWide, sans-serif;
	list-style: none;
	margin: 0;
	padding: 0;
}

.audi-ordered-list-s {
	font-size: 1.3rem;
	line-height: 1.8rem;
}

.audi-ordered-list-m {
	font-size: 1.6rem;
	line-height: 2.4rem;
}

.audi-ordered-list-s__item,
.audi-ordered-list-m__item {
	counter-increment: audi-custom-counter;
	display: table-row;
}

.audi-ordered-list-s__item::before,
.audi-ordered-list-m__item::before {
	content: counter(audi-custom-counter) ".";
	display: table-cell;
	font-weight: var(--font-weight-bold);
	text-align: right;
}

.audi-ordered-list-s__item-text,
.audi-ordered-list-m__item-text {
	display: table-cell;
	padding-left: var(--space-s);
	padding-top: var(--space-xs);
	width: 100%;
}

.audi-ordered-list-s__item:first-of-type .audi-ordered-list-s__item-text,
.audi-ordered-list-m__item:first-of-type .audi-ordered-list-m__item-text {
	padding-top: 0;
}

.audi-button {
	align-items: center;
	border: 1px solid transparent;
	box-sizing: border-box;
	cursor: pointer;
	display: inline-flex;
	font-family: AudiTypeWide, sans-serif;
	font-size: 1.6rem;
	font-weight: normal;
	justify-content: center;
	line-height: 2.4rem;
	overflow: hidden;
	padding: calc(var(--space-s) + var(--space-xxs)) var(--space-xl);
	position: relative;
	text-decoration: none;
	width: 100%;
}

@media all and (min-width: 480px) {
	.audi-button {
		max-width: 600px;
		min-width: 240px;
		width: auto;
	}
}

.audi-button__text {
	display: block;
	text-align: center;
}

.audi-button__icon {
	align-self: flex-start;
	display: block;
	flex: none;
	height: var(--icon-size-small);
	margin-right: var(--space-s);
	width: var(--icon-size-small);
}

.audi-button__icon ~ .audi-button__text {
	text-align: left;
}

/* Default Color and Manifestations */
.audi-button,
.audi-button:active,
.audi-button[data-state="active"] {
	background-color: var(--color-black);
	color: var(--color-white);
}

.audi-button:hover,
.audi-button:focus {
	background-color: var(--color-grey-70);
}

.audi-button[data-state="inactive"],
.audi-button--inactive {
	background-color: var(--color-grey-15);
	pointer-events: none; /* default behaviour for all buttons */
}

/* Ghost Button */
.audi-button--ghost,
.audi-button--ghost:active,
.audi-button--ghost[data-state="active"] {
	background-color: transparent;
	border-color: var(--color-black);
	color: var(--color-black);
}

.audi-button--ghost:hover,
.audi-button--ghost:focus {
	background-color: transparent;
	border-color: var(--color-grey-70);
	color: var(--color-grey-70);
}

.audi-button--ghost[data-state="inactive"],
.audi-button--ghost.audi-button--inactive {
	background-color: transparent;
	border-color: var(--color-grey-15);
	color: var(--color-grey-15);
}

/* standard Button Inverted */
.audi-button--inverted,
.audi-button--inverted:active,
.audi-button--inverted[data-state="active"] {
	background-color: var(--color-white);
	color: var(--color-black);
}

.audi-button--inverted:hover,
.audi-button--inverted:focus {
	background-color: var(--color-grey-30);
}

.audi-button--inverted[data-state="inactive"],
.audi-button--inverted.audi-button--inactive {
	background-color: var(--color-grey-60);
}

/* Button Ghost Inverted */
.audi-button--ghost.audi-button--inverted,
.audi-button--ghost.audi-button--inverted:active {
	background-color: transparent;
	border-color: var(--color-white);
	color: var(--color-white);
}

.audi-button--ghost.audi-button--inverted:hover,
.audi-button--ghost.audi-button--inverted:focus {
	background-color: transparent;
	border-color: var(--color-grey-30);
	color: var(--color-grey-30);
}

.audi-button--ghost.audi-button--inverted.audi-button--inactive {
	background-color: transparent;
	border-color: var(--color-grey-60);
	color: var(--color-grey-60);
}

.audi-button--loading {
	background-color: var(--color-grey-15);
	background-image: url("./assets/icons/audi-loader.svg");
	background-position: center center;
	background-repeat: no-repeat;
	background-size: var(--icon-size-small) var(--icon-size-small);
	color: rgba(0, 0, 0, 0);
	pointer-events: none;
}

.audi-button--arrow,
.audi-button--cancel,
.audi-button--checkmark {
	display: inline-flex;
	text-align: left;
}

.audi-button--arrow::before,
.audi-button--cancel::before,
.audi-button--checkmark::before {
	align-self: flex-start;
	background-image: url("./assets/icons/forward-small.svg");
	content: " ";
	display: block;
	filter: invert(1);
	flex: none;
	height: var(--icon-size-small);
	margin-right: var(--space-s);
	vertical-align: middle;
	width: var(--icon-size-small);
}

.audi-button--cancel::before {
	background-image: url("./assets/icons/cancel-small.svg");
}

.audi-button--checkmark::before {
	background-image: url("./assets/icons/select-small.svg");
}

.audi-button--icon-right {
	flex-direction: row-reverse;
}

.audi-button--icon-right::before {
	margin-left: var(--space-s);
	margin-right: 0;
}

.audi-button--ghost.audi-button--arrow::before,
.audi-button--ghost.audi-button--cancel::before,
.audi-button--ghost.audi-button--checkmark::before,
.audi-button--inverted.audi-button--arrow::before,
.audi-button--inverted.audi-button--cancel::before,
.audi-button--inverted.audi-button--checkmark::before {
	filter: invert(0);
}

.audi-button--ghost.audi-button--inverted.audi-button--arrow::before,
.audi-button--ghost.audi-button--inverted.audi-button--cancel::before,
.audi-button--ghost.audi-button--inverted.audi-button--checkmark::before {
	filter: invert(1);
}

.audi-button--ghost.audi-button--inactive.audi-button--arrow::before,
.audi-button--ghost.audi-button--inactive.audi-button--cancel::before,
.audi-button--ghost.audi-button--inactive.audi-button--checkmark::before {
	filter: invert(.85);
}

.audi-button--ghost.audi-button--arrow:hover::before,
.audi-button--ghost.audi-button--cancel:hover::before,
.audi-button--ghost.audi-button--checkmark:hover::before,
.audi-button--ghost.audi-button--inverted.audi-button--arrow:focus::before,
.audi-button--ghost.audi-button--inverted.audi-button--cancel:focus::before,
.audi-button--ghost.audi-button--inverted.audi-button--checkmark:focus::before {
	filter: invert(.3);
}

.audi-button--ghost.audi-button--inverted.audi-button--arrow:hover::before,
.audi-button--ghost.audi-button--inverted.audi-button--cancel:hover::before,
.audi-button--ghost.audi-button--inverted.audi-button--checkmark:hover::before,
.audi-button--ghost.audi-button--inverted.audi-button--arrow:focus::before,
.audi-button--ghost.audi-button--inverted.audi-button--cancel:focus::before,
.audi-button--ghost.audi-button--inverted.audi-button--checkmark:focus::before {
	filter: invert(.7);
}

.audi-button--ghost.audi-button--inverted.audi-button--inactive.audi-button--arrow > .audi-button__text::before,
.audi-button--ghost.audi-button--inverted.audi-button--inactive.audi-button--cancel > .audi-button__text::before,
.audi-button--ghost.audi-button--inverted.audi-button--inactive.audi-button--checkmark > .audi-button__text::before {
	filter: invert(.4);
}

.audi-unordered-list-s,
.audi-unordered-list-m {
	font-family: AudiTypeWide, sans-serif;
	list-style-type: none;
	margin: 0;
	padding: 0;
}

.audi-unordered-list-s {
	font-size: 1.3rem;
	line-height: 1.8rem;
}

.audi-unordered-list-m {
	font-size: 1.6rem;
	line-height: 2.4rem;
}

.audi-unordered-list-s__item,
.audi-unordered-list-m__item {
	display: flex;
	margin-top: var(--space-xs);
}

.audi-unordered-list-s__item:first-of-type,
.audi-unordered-list-m__item:first-of-type {
	margin-top: 0;
}

.audi-unordered-list-s__item-icon,
.audi-unordered-list-m__item-icon {
	flex: none;
	height: var(--icon-size-small);
	margin-right: var(--space-xs);
	width: var(--icon-size-small);
}

.audi-unordered-list-m__item-icon {
	margin-right: var(--space-s);
}

.audi-unordered-list-s__item-text {
	padding-top: var(--space-xxs);
}

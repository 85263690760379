@charset "UTF-8";

/* base rules & variable  */
@import "audi-variables.css";

/* font & typography rules */
@import "https://assets.audi.com/audi-fonts/1/audi-fonts-css.min.css";
@import "typography/audi-typography.css";

/* element rules : Buttons, Links & Lists */
@import "elements/audi-elements.css";

.audi-copy-s,
.audi-copy-m {
	font-family: AudiTypeWide, sans-serif;
}

.audi-copy--bold {
	font-weight: var(--font-weight-bold);
}

.audi-copy--inverted {
	color: var(--color-white);
}

.audi-copy-s {
	font-size: 1.3rem;
	line-height: 1.8rem;
}

.audi-copy-m {
	font-size: 1.6rem;
	line-height: 2.4rem;
}

.audi-link--inactive {
	color: var(--color-grey-30);
	pointer-events: none;
}

.audi-link--underline {
	text-decoration: underline;
}

.audi-link--arrow {
	display: inline;
	padding: 0 calc(var(--space-xs) + var(--icon-size-small)) 0 0;
}

.audi-link-m.audi-link--arrow {
	padding: 0 calc(var(--space-s) + var(--icon-size-small)) 0 0;
}

.audi-link--arrow-front {
	align-items: flex-start;
	display: inline-flex;
	flex-direction: row-reverse;
	justify-content: flex-end;
	padding: 0 0 0 calc(var(--space-xs) + var(--icon-size-small));
}

.audi-link-m.audi-link--arrow-front {
	padding: 0 0 0 calc(var(--space-xs) + var(--icon-size-small));
}

.audi-link--inverted {
	color: var(--color-white);
}

.audi-link--inverted:focus,
.audi-link--inverted:hover {
	color: var(--color-grey-30);
}

.audi-link--inverted.audi-link--inactive {
	color: var(--color-grey-70);
}

.audi-link--arrow::after,
.audi-link--arrow-front::before {
	background-image: url("./assets/icons/forward-small.svg");
	content: "";
	display: inline-block;
	height: var(--icon-size-small);
	position: absolute;
	transform: translateX(var(--space-xs)) translateY(-3px);
	vertical-align: middle;
	width: var(--icon-size-small);
}

.audi-link--arrow-front::before {
	flex: none;
	transform: translateX(calc(-1 * var(--icon-size-small) - var(--space-xs)))
		translateY(-3px);
	vertical-align: top;
}

.audi-link-m.audi-link--arrow::after {
	transform: translateX(var(--space-xs)) translateY(-1px);
}

.audi-link-m.audi-link--arrow-front::before {
	transform: translateX(calc(-1 * var(--icon-size-small) - var(--space-xs)))
		translateY(-1px);
}

.audi-link--inverted.audi-link--arrow::after,
.audi-link--inverted.audi-link--arrow-front::before {
	filter: invert(1);
}

.audi-link--arrow.audi-link--inactive::after,
.audi-link--inverted.audi-link--arrow:hover::after,
.audi-link--inverted.audi-link--arrow:focus::after,
.audi-link--arrow-front.audi-link--inactive::before,
.audi-link--inverted.audi-link--arrow-front:hover::before,
.audi-link--inverted.audi-link--arrow-front:focus::before {
	filter: invert(.7);
}

.audi-link--inverted.audi-link--arrow.audi-link--inactive::after,
.audi-link--inverted.audi-link--arrow-front.audi-link--inactive::before {
	filter: invert(.3);
}

.audi-list--inverted {
	color: var(--color-white);
}

.audi-ordered-list-s .audi-list__item,
.audi-ordered-list-m .audi-list__item {
	counter-increment: audi-custom-counter;
	display: table-row;
}

.audi-ordered-list-s .audi-list__item::before,
.audi-ordered-list-m .audi-list__item::before {
	content: counter(audi-custom-counter) ".";
	display: table-cell;
	font-weight: var(--font-weight-bold);
	padding-right: var(--space-s);
	padding-top: var(--space-xs);
	text-align: right;
}

.audi-unordered-list-s .audi-list__item::before,
.audi-unordered-list-m .audi-list__item::before {
	content: "";
	flex: none;
	height: var(--icon-size-small);
	margin-right: var(--space-xs);
	transform: translateY(-3px);
	width: var(--icon-size-small);
}

.audi-unordered-list-m .audi-list__item::before {
	margin-right: var(--space-s);
	transform: translateY(-1px);
}

.audi-unordered-list-s.audi-list--arrow .audi-list__item::before,
.audi-unordered-list-m.audi-list--arrow .audi-list__item::before {
	background-image: url("./assets/icons/forward-small.svg");
}

.audi-unordered-list-s.audi-list--checkmark .audi-list__item::before,
.audi-unordered-list-m.audi-list--checkmark .audi-list__item::before {
	background-image: url("./assets/icons/select-small.svg");
}

.audi-ordered-list-s .audi-list__item::before:first-of-type,
.audi-ordered-list-m .audi-list__item::before:first-of-type {
	padding-top: 0;
}

.audi-unordered-list-s .audi-list__item,
.audi-unordered-list-m .audi-list__item {
	display: flex;
	margin-top: var(--space-xs);
}

.audi-unordered-list-s .audi-list__item:first-of-type,
.audi-unordered-list-m .audi-list__item:first-of-type {
	margin-top: 0;
}

.audi-unordered-list-s.audi-list--inverted .audi-list__item::before,
.audi-unordered-list-m.audi-list--inverted .audi-list__item::before {
	filter: invert(1);
}

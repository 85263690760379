.audi-link-m {
	color: var(--color-black);
	cursor: pointer;
	display: inline-block;
	font-family: AudiTypeWide, sans-serif;
	font-size: 1.6rem;
	line-height: 2.4rem;
	text-decoration: none;
}

.audi-link-m__icon {
	flex: none;
	height: var(--icon-size-small);
	width: var(--icon-size-small);
}

.audi-link-m[data-state="inactive"] {
	color: var(--color-grey-30);
	pointer-events: none;
}

.audi-link-m--underline {
	text-decoration: underline;
}

.audi-link-m--arrow {
	font-size: 0;
}

.audi-link-m--arrow .audi-link-m__text {
	display: inline;
	font-size: 1.6rem;
	vertical-align: middle;
}

.audi-link-m--arrow-front {
	align-items: flex-start;
	display: inline-flex;
	flex-direction: row-reverse;
	justify-content: flex-end;
}

.audi-link-m--arrow .audi-link-m__text,
.audi-link-m--arrow-front .audi-link-m__icon {
	margin-right: var(--space-xs);
}

.audi-link-m:hover,
.audi-link-m:focus {
	color: var(--color-grey-70);
}

.audi-link-m .audi-link-m__icon {
	flex: none; /* arrow front */
	height: var(--icon-size-small);
	vertical-align: middle; /* arrow */
	width: var(--icon-size-small);
}

.audi-link-m--inverted {
	color: var(--color-white);
}

.audi-link-m--inverted:hover,
.audi-link-m--inverted:focus {
	color: var(--color-grey-30);
}

.audi-link-m--inverted[data-state="inactive"] {
	color: var(--color-grey-70);
}

.audi-link-l {
	color: var(--color-black);
	cursor: pointer;
	display: inline-block;
	font-family: AudiTypeWide, sans-serif;
	font-size: 1.6rem;
	line-height: 2.4rem;
	text-decoration: none;
}

.audi-link-l:hover,
.audi-link-l:focus {
	color: var(--color-grey-70);
}

@media all and (min-width: 768px) {
	.audi-link-l {
		font-size: 2.1rem;
		line-height: 3rem;
	}
}
